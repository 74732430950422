.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height */
  padding: 20px;
  box-sizing: border-box;
}

.json-viewer {
  display: flex; /* Use flexbox to align items horizontally */
  flex: 1; /* Takes up remaining space */
  margin-top: 20px; /* Space above the json-viewer */
}

.json-input-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px; /* Space between input and divider */
}

.json-input {
  flex: 1; /* Takes up most of the space */
  margin-bottom: 10px;
}

.button-group {
  display: flex; /* Align buttons in a row */
  margin-bottom: 10px; /* Space below buttons */
}

.parse-button, .clear-button {
  margin-right: 5px; /* Space between buttons */
  padding: 10px 20px; /* Increase padding for larger buttons */
  font-size: 16px;    /* Increase font size for larger text */
  border-radius: 5px; /* Optional: add some border radius for rounded corners */
  height: 40px;       /* Increase button height */
  width: auto;        /* Let the width adjust automatically based on content */
  cursor: pointer;    /* Change cursor to pointer */
}


.clear-button {
  background-color: #f44336; /* Red background for clear button */
  color: white; /* White text */
}

.search-input {
  margin-top: 10px; /* Space above the search input */
  padding: 5px; /* Padding for better aesthetics */
}

.divider {
  cursor: ew-resize; /* Change cursor to indicate resizable */
  width: 5px; /* Width of the divider */
  background-color: #ccc; /* Color of the divider */
}

.json-output {
  flex: 1; /* Takes up remaining width */
  overflow-y: auto; /* Allows scrolling if needed */
  border: 1px solid #ccc; /* Optional border for output area */
  padding: 10px; /* Optional padding */
  background-color: #f9f9f9; /* Optional background color */
}

.json-viewer-container {
  position: relative; /* Position container relative for overlay */
}

.json-search-overlay {
  top: 0px;
  z-index: 10;/* Slight transparency */
  border-radius: 5px;
}

.json-search-overlay input {
  padding: 5px;
  margin-right: 5px;
}

.json-search-overlay button {
  margin-left: 5px;
}

.json-output {
  position: relative;
  overflow: scroll;
}

.highlighted {
  background-color: yellow;
  color: black;
}
