.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4CAF50; /* Green background */
    padding: 10px 20px; /* Padding for navbar */
    color: white; /* White text color */
  }
  
  .navbar-title {
    font-size: 24px; /* Title font size */
  }
  
  .navbar-tabs {
    display: flex; /* Align tabs in a row */
  }
  
  .navbar-tab {
    background: none; /* No background */
    border: none; /* No border */
    color: white; /* Text color */
    padding: 10px 15px; /* Padding for tabs */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s; /* Transition for hover */
  }
  
  .navbar-tab:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light hover effect */
  }
  
  .active {
    border-bottom: 2px solid white; /* Active tab indicator */
  }
  